@import url('https://fonts.googleapis.com/css2?family=Abel&family=Bigshot+One&display=swap');

.contact-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contact-container {
  background-color: white;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.contact-content {
  font-family: 'Abel', sans-serif;
}

.contact-title {
  font-family: 'Bigshot One', cursive;
  font-size: 3rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

input,
textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Abel', sans-serif;
}

textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-btn {
  background-color: #333;
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Abel', sans-serif;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #555;
}

.status-message {
  margin-top: 1rem;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 2rem;
    width: 92%;
  }

  .contact-title {
    font-size: 2.5rem;
  }

  .contact-subtitle {
    font-size: 1rem;
  }
}