.header {
  padding: 1rem;
  background-color: #ffffff;
  height: 10vh; /* 10% of viewport height */
  margin: 0;
}

.header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo a {
  font-family: 'Bigshot One', cursive;
  font-size: 2.5rem;
  color: #000000;
  text-decoration: none;
  display: inline-block;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.logo.loaded a {
  transform: translateX(0);
  opacity: 1;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
  z-index: 1000;
}

.menu-icon span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #000000;
  transition: all 0.3s ease;
}

.menu-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon.open span:nth-child(2) {
  opacity: 0;
}

.menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

.nav-links {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.nav-links.open {
  display: flex;
}

.nav-links li {
  margin: 1rem 0;
}

.nav-links li a {
  font-family: 'Abel', sans-serif;
  font-size: 1.5rem;
  color: #333333;
  text-decoration: none;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  text-decoration: underline;
}

.contact-btn {
  background-color: #000000;
  color: #ffffff !important;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1.5rem;
}

.contact-btn:hover {
  background-color: #333333;
  text-decoration: none !important;
}

@media (min-width: 768px) {
  .header {
    padding: 1rem 6rem;
  }

  .menu-icon {
    display: none;
  }

  .nav-links {
    display: flex;
    position: static;
    flex-direction: row;
    background-color: transparent;
    height: auto;
    padding: 0;
  }

  .nav-links li {
    margin: 0 0 0 3rem;
    opacity: 0;
    transform: translateX(50px);
    transition: transform 0.5s ease, opacity 0.5s ease;
    transition-delay: calc(0.1s * var(--item-index));
  }

  .nav-links.loaded li {
    opacity: 1;
    transform: translateX(0);
  }

  .nav-links li a {
    font-size: 1.3rem;
  }
  .contact-btn{
    font-size: 1.3rem;

  }
}