/* src/components/NotFound/NotFound.css */
/* @import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&display=swap'); */

.not-found-container {
  min-height: 100vh;
  background-color: #000000;
  position: relative;
  overflow: hidden;
}

.noise {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    repeating-radial-gradient(#000 0 0.0001%, #fff 0 0.0002%) 50% 0/2500px 2500px,
    repeating-conic-gradient(#000 0 0.0001%, #fff 0 0.0002%) 50% 50%/2500px 2500px;
  opacity: 0.05;
  z-index: 1;
}

.overlay {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background-size: auto 4px;
  z-index: 2;
}

.overlay::before {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    transparent 0%,
    rgba(255, 255, 255, 0.2) 2%,
    rgba(255, 255, 255, 0.8) 3%,
    rgba(255, 255, 255, 0.2) 3%,
    transparent 100%
  );
  background-repeat: no-repeat;
  animation: scan 7.5s linear 0s infinite;
}

@keyframes scan {
  0% {
    background-position: 0 -100vh;
  }
  35%, 100% {
    background-position: 0 100vh;
  }
}

.terminal {
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 1000px;
  padding: 4rem;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'JetBrains Mono', monospace;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.4),
               0 0 2px rgba(255, 255, 255, 0.8);
}

.output {
  margin: 1rem 0;
  position: relative;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.4),
               0 0 2px rgba(255, 255, 255, 0.8);
}

.output::before {
  content: "> ";
}

.terminal h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.errorcode {
  color: #ffffff;
}

.terminal a {
  color: #ffffff;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
}

.terminal a::before {
  content: "[";
  margin-right: 0.5rem;
}

.terminal a::after {
  content: "]";
  margin-left: 0.5rem;
}

.terminal a:hover {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .terminal {
    padding: 2rem;
  }
  
  .terminal h1 {
    font-size: 2rem;
  }
}