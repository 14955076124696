.footer {
    flex-shrink: 0;
    background-color: #000;
    color: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.footer-text {
    font-family: 'Bigshot One', cursive;
    font-size: 2.5rem;
    color: #fff; 
}

.footer-text .dots {
    color: #fff; 
}

.footer-info {
    display: flex;
    gap: 1rem;
}

.social-link {
    color: #fff;
    font-size: 2rem;
    transition: color 0.3s ease;
}

.social-link:hover {
    color: #ccc;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-info {
        margin-bottom: 1rem;
    }
}