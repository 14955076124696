@import url('https://fonts.googleapis.com/css2?family=Abel&family=Bigshot+One&display=swap');

@keyframes fadeInFromRight {
  from { transform: translateX(50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInFromLeft {
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Add new loading animation */
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.home-container {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
}

.intro-text {
  font-family: 'Abel', sans-serif;
  font-size: clamp(1rem, 3vw, 1.8rem);
  text-align: center;
  margin-bottom: 2rem;
  z-index: 2;
}

.title-container {
  position: relative;
  text-align: center;
  margin-bottom: 2rem;
}

.fullstack, .webdeveloper {
  font-family: 'Bigshot One', cursive;
  font-size: clamp(2rem, 10vw, 8rem);
  line-height: 1.1;
  margin: 0;
  opacity: 0;
}

.animate-fullstack {
  animation: fadeInFromRight 1s ease-out forwards;
}

.animate-webdeveloper {
  animation: fadeInFromLeft 1s ease-out forwards;
  animation-delay: 0.5s;
}

.webdeveloper {
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: transparent;
}

.content-wrapper {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

/* Updated image wrapper styles */
.image-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Updated image placeholder styles */
.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  z-index: 1;
}

.image-placeholder .animate-spin {
  animation: spin 1s linear infinite;
}

/* Updated developer image styles */
.developer-image {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  will-change: transform, opacity;
}

.developer-image.loaded {
  opacity: 1;
}

.developer-image.desktop {
  transition: transform 0.1s ease-out;
  will-change: transform;
}

.button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  z-index: 3;
}

/* Rest of your existing styles remain unchanged */
.btn {
  font-family: 'Abel', sans-serif;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  margin: 0 0.5rem;
}

.download-btn {
  background-color: black;
  color: white;
}

.hire-btn {
  background-color: white;
  color: black;
  border: 1px solid black;
}

/* Contact Form Styles */
.contact-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contact-container {
  background-color: white;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.contact-content {
  font-family: 'Abel', sans-serif;
}

.contact-title {
  font-family: 'Bigshot One', cursive;
  font-size: 3rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Abel', sans-serif;
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-btn {
  background-color: #333;
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Abel', sans-serif;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #555;
}

.status-message {
  margin-top: 1rem;
  text-align: center;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .home-container {
    justify-content: flex-start;
    padding-top: 5rem;
    position: relative;
    min-height: 90vh;
  }

  .content-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    max-width: 470px;
  }

  .image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    position: relative;
  }

  .developer-image {
    width: 100%;
    height: auto;
    margin-bottom: 0;
    display: block;
  }

  .button-container {
    position: absolute;
    bottom: 20%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    z-index: 3;
    width: 100%;
  }

  .btn {
    flex-grow: 0;
    min-width: 250px;
    padding: 0.75rem 1.5rem;
  }

  .fullstack, .webdeveloper {
    font-size: clamp(4rem, 15vw, 13rem);
  }
}

@media (max-width: 768px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  .home-container {
    min-height: 100%;
    justify-content: flex-start;
    padding: 1rem;
    height: auto;
  }

  .intro-text {
    margin-bottom: 1rem;
  }

  .title-container {
    margin-bottom: 1rem;
  }

  .content-wrapper {
    flex-direction: column;
    margin-top: 1rem;
    max-width: 100%;
    margin: 0;
  }

  .image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .developer-image {
    width: 70%; /* Increased from 70% */
    max-width: 400px; /* Increased from 300px */
    height: auto;
    margin: 0 auto;
    display: block;
    margin-bottom: 0;
  }

  .button-container {
    position: static;
    flex-direction: column;
    padding: 0;
    margin-top: 1rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }

  .btn {
    width: 100%;
    margin: 0.5rem 0;
  }

  .contact-container {
    padding: 2rem;
  }

  .contact-title {
    font-size: 2.5rem;
  }

  .contact-subtitle {
    font-size: 1rem;
  }

  .webdeveloper {
    -webkit-text-stroke: 1px black;
  }
}