@import url('https://fonts.googleapis.com/css2?family=Abel&family=Bigshot+One&display=swap');

.works-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background-color: #ffffff;
  font-family: 'Abel', sans-serif;
}

.project {
  margin-bottom: 6rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid #e0e0e0;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
}

.project:last-child {
  border-bottom: none;
}

.project-content {
  display: flex;
  flex-direction: column;
}

.project-title {
  font-family: 'Bigshot One', cursive;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #000000;
  text-decoration: underline;
}

.project-subtitle {
  font-size: 1.2rem;
  color: #666666;
  margin-bottom: 2rem;
}

.image-wrapper {
  margin-bottom: 2rem;
  border-radius: 10px;
  overflow: hidden;
  filter: grayscale(100%);
  transition: all 0.3s ease;
  width: 100%;
  max-width: 800px; /* Set a consistent maximum width */
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.image-wrapper:hover {
  filter: grayscale(0%);
  transform: scale(1.02);
}

.project-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain; /* This ensures the image maintains its aspect ratio */
}

.description {
  color: #333333;
}

.description-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.view-project-btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #000000;
  color: #ffffff;
  text-decoration: none;
  border-radius: 25px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.view-project-btn:hover {
  background-color: #333333;
  transform: scale(1.05);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .project-title {
    font-size: 2.2rem;
  }
}

@media (max-width: 768px) {
  .works-container {
    padding: 2rem 1rem;
  }

  .project-title {
    font-size: 2rem;
  }

  .project-subtitle {
    font-size: 1rem;
  }

  .description-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .project-title {
    font-size: 1.8rem;
  }

  .project-subtitle {
    font-size: 0.9rem;
  }

  .description-text {
    font-size: 0.9rem;
  }
}