@import url('https://fonts.googleapis.com/css2?family=Abel&family=Bigshot+One&display=swap');

body {
  font-family: 'Abel', sans-serif;
}

.about-container {
  max-width: 85%;
  margin: 0 auto;
  overflow: hidden;
}

.section-title {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-family: 'Bigshot One', cursive;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
}

/* Common section styling */
.about-section,
.work-experience-section,
.skills-section,
.certification-section,
.clubs-section {
  padding: 2rem 0;
  margin: 2rem 0;
  overflow: hidden;
}

/* About Section */
.about-content {
  display: flex;
  align-items: center;
  gap: 2rem;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.about-content:hover {
  filter: grayscale(0%);
}

.profile-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  opacity: 0;
  animation: fadeInLeft 1s ease-out forwards;
  transition: transform 0.3s ease;
}

.about-content:hover .profile-image {
  transform: scale(1.05);
}

.about-text {
  flex: 1;
  opacity: 0;
  animation: fadeInRight 1s ease-out forwards;
}

.about-text p, 
.about-text li, 
.experience-details p, 
.certification p, 
.skills-list p {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* Work Experience Section */

.experience-details h3 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  font-weight: 700; /* Made bolder */
}

.experience-logo {
  width: 100%;
  max-width: 160px;
  height: auto;
  transition: transform 0.3s ease; /* Added transition for hover effect */
}

.experience-logo:hover {
  transform: scale(1.05); /* Added hover effect */
}
.experience {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  filter: grayscale(100%);
  transition: all 0.3s ease;
}

.experience:hover {
  filter: grayscale(0%);
  transform: scale(1.02);
}

.experience-logo {
  width: 100%;
  max-width: 160px;
  height: auto;
}

.experience-details h3 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

/* Skills Section */
.skills-content {
  display: flex;
  align-items: center;
  gap: 4rem;
  filter: grayscale(100%);
  transition: all 0.3s ease;
  padding: 2rem;
}

.skills-content:hover {
  filter: grayscale(0%);
  transform: scale(1.02);
}

.icon-cloud-container {
  flex: 1;
  max-width: 500px;
}

/* Certifications Section */
.certifications {
  display: flex;
  gap: 2rem;
  padding: 2rem 0;
}

.certification {
  flex: 1;
  filter: grayscale(100%);
  transition: all 0.3s ease;
  padding: 2rem;
}

.certification:hover {
  filter: grayscale(0%);
  transform: scale(1.05);
}

.certification img {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 1rem;
}

.certification h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

/* Clubs Section - Carousel */
.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 2rem 0;
}

.carousel-track {
  display: flex;
  animation: scroll 20s linear infinite;
  width: fit-content;
}

.carousel-track:hover {
  animation-play-state: paused;
}

.carousel-item {
  flex: 0 0 250px;
  padding: 1rem;
  margin-right: 2rem;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;
  filter: grayscale(100%);
}

.carousel-item:hover {
  transform: scale(1.05);
  filter: grayscale(0%);
}

.carousel-logo-container {
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.carousel-logo {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  transition: all 0.3s ease;
}

.carousel-club-name {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0.5rem 0;
}

/* Animations */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .section-title {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .about-content,
  .certifications,
  .skills-content,
  .experience {
    flex-direction: column;
  }

  .profile-image {
    max-width: 300px;
    margin: 0 auto 1rem;
  }

  .icon-cloud-container {
    max-width: 100%;
  }

  .section-title {
    font-size: 2.2rem;
  }

  .carousel-item {
    flex: 0 0 200px;
  }

  .about-text p, 
  .about-text li, 
  .experience-details p, 
  .certification p, 
  .skills-list p {
    font-size: 1.1rem;
  }

  .experience-logo {
    max-width: 150px;
  }

  .experience-details h3 {
    font-size: 1.6rem;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 2rem;
  }

  .about-text p, 
  .about-text li, 
  .experience-details p, 
  .certification p, 
  .skills-list p {
    font-size: 1rem;
  }

  .carousel-item {
    flex: 0 0 150px;
    margin-right: 1rem;
  }

  .experience-logo {
    max-width: 100px;
  }

  .experience-details h3 {
    font-size: 1.4rem;
  }
}